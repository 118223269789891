import './App.scss'
import { Route, Routes } from 'react-router-dom'
import RegistrationForm from './RegistrationForm'

export default function App () {
  return (
    <div className="App">
      <Routes>
        <Route path="/o/:org/" element={<RegistrationForm/>}/>
        <Route path="/org/:org/" element={<RegistrationForm/>}/>
        <Route path="/:uhash/" element={<RegistrationForm/>}/>
        <Route path="/" element={<RegistrationForm/>}/>
      </Routes>
    </div>
  )
}