import { Button, Col, OverlayTrigger, Popover, Row } from 'react-bootstrap'
import InputField from '../components/InputField'
import * as yup from 'yup'
import '../components/validations'

import { useEffect } from 'react'
import { useAnalytics } from 'use-analytics'

const familyStatusOptions = [
  { value: 'single', label: 'רווק/ה' },
  { value: 'married', label: 'נשוי/ה' },
  { value: 'separated', label: 'פרוד/ה' },
  { value: 'divorced', label: 'גרוש/ה' },
  { value: 'widow', label: 'אלמנ/ה' },
]
const genderOptions = [{ value: 'male', label: 'זכר' }, { value: 'female', label: 'נקבה' }]

// const invalidDuplicateId = 'מספר הזהות זהה למספר של בן הזוג השני'
const schema = yup.object().shape({
  israeli_id: yup.string().required().israeliId(),
  partner: yup.object().shape({
    enabled: yup.bool(),
    israeli_id: yup.string().required().israeliId(),
    email: yup.string().email().required(),
    phone: yup.string().required().ILMobile(),
    first_name: yup.string().required().hebrewOnly(),
    last_name: yup.string().required().hebrewOnly(),
    father_name: yup.string().required().hebrewOnly(),
    birthday: yup.date().required(),
    gender: yup.string().required(),
    family_status: yup.string().required(),
    birth_country: yup.string().notRequired().hebrewOnly(),
    aliya_year: yup.mixed().when('partner.birth_country', {
      is: v => v === undefined || v === '' || v === 'ישראל',
      then: yup.mixed().notRequired(),
      otherwise: yup.number().required(),
    }),
    signature: yup.mixed().when('registration_mode', {
      is: v => v === 'offline',
      then: yup.mixed().required(),
    })
  }),
  registration_mode: yup.string(),
})

export const PartnerDetails = (props) => {
  let { values: { partner: { birth_country }, registration_mode }, setFieldValue, prev } = props

  const { track } = useAnalytics()
  useEffect(() => {
    track('PartnerDetails')
  }, [track])

  const removePartner = () => {
    setFieldValue('partner.enabled', false)
    track('removePartner')
    prev()
  }

  const partnerTooltip = (
    <Popover>
      <Popover.Header>אתה בטוח שברצונך לעבור להתפקדות יחיד?</Popover.Header>
      <Popover.Body>
        <p>
          התפקדות זוגית מקנה הנחה של 32 שקלים בדמי החברות השנתיים.
        </p>
      </Popover.Body>
    </Popover>
  )

  return <>
  <Row>
    <Col>
      <h3>פרטי בן/בת זוג</h3>
    </Col>
  </Row>
  <Row>
    <InputField validationSchema={schema} name="partner.first_name" type="text" label="שם פרטי"/>
    <InputField validationSchema={schema} name="partner.last_name" type="text" label="שם משפחה"/>
    <InputField validationSchema={schema} name="partner.israeli_id" type="tel" label="מספר זהות"/>
  </Row>
  <Row>
    <InputField validationSchema={schema} name="partner.email" type="email" placeholder="your@email.com"
                label="אימייל"/>
    <InputField validationSchema={schema} name="partner.phone" type="phone" placeholder="050-1234-567"
                label="טלפון נייד"/>
    <InputField validationSchema={schema} name="partner.father_name" type="text" label="שם האב"/>
  </Row>
  <Row>
    <InputField validationSchema={schema} name="partner.birthday" type="datepicker" label="תאריך לידה">
      ההתפקדות מגיל 17 ומעלה
    </InputField>
    <InputField validationSchema={schema} type="select" name="partner.gender" label="מגדר"
                options={genderOptions}/>
    <InputField validationSchema={schema} type="select" name="partner.family_status" label="מצב משפחתי"
                options={familyStatusOptions}/>
    <InputField validationSchema={schema} type="text" name="partner.birth_country" label="ארץ לידה"
                placeholder="ישראל"/>
    {birth_country !== '' && birth_country !== undefined && birth_country !== 'ישראל' &&
      <InputField validationSchema={schema} type="number" name="partner.aliya_year" label="שנת עליה"
                  placeholder="" markRequired/>}
  </Row>
  { registration_mode === 'offline' && <Row>
      <InputField validationSchema={schema} type="signature" name="partner.signature" label="חתימה"/>
    </Row>
  }
  <Row>
    <Col>
      {props.NavigationButtons} {' '}
      <OverlayTrigger trigger={['hover', 'focus']} placement="auto" delay={{ show: 250, hide: 400 }}
                      overlay={partnerTooltip}>
        <Button onClick={removePartner} variant={'outline-danger'}>הסרת בן/בת זוג</Button>
      </OverlayTrigger>
    </Col>
  </Row>
</>
}
PartnerDetails.label = 'התפקדות זוגית'
PartnerDetails.validationSchema = schema
PartnerDetails.isEnabled = (values) => {
  return values.partner.enabled
}