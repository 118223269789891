import SignatureCanvas from 'react-signature-canvas'
import { forwardRef, useEffect } from 'react'
import { FaEraser, FaUndo } from 'react-icons/fa'
import './SignaturePad.css'
import { Button } from 'react-bootstrap'

const SignaturePad = forwardRef((props, ref) => {
  let onEnd = () => {
    if (props.onChange) {
      props.onChange(ref.current.toDataURL())
    }
  }

  useEffect(() => {
    if (props.initialValue) {
      ref.current.fromDataURL(props.initialValue)
    }
  }, [])

  let erase = () => {
    ref.current.clear()
    onEnd()
  }
  let undo = () => {
    var data = ref.current.toData();
    if (data) {
      data.pop(); // remove the last dot or line
      ref.current.fromData(data);
      onEnd()
    }
  }

  return <div className={'signature_pad'}>
    <div className={'pad'}>
      <SignatureCanvas
        penColor={'rgb(0,15,85)'}
        onEnd={onEnd}
        backgroundColor={'rgba(255,255,255, 0)'}
        ref={ref}/>
    </div>
    <div className="controllers">
      <Button variant="outline-danger" onClick={erase}><FaEraser/></Button>
      <Button variant="outline-warning" onClick={undo}><FaUndo/></Button>
    </div>
  </div>
})

export default SignaturePad