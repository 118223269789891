import {Alert, Spinner} from "react-bootstrap";

export function ProcessingMsg() {
    return <Alert variant="secondary">
        <h2>
            נא להמתין
            {' '}<Spinner animation="grow" role="status">
            <span className="visually-hidden">נא להמתין…</span>
        </Spinner>
        </h2>
        <p>אנחנו שולחים את בקשת ההתפקדות שלך לליכוד… זה עשוי לקחת כמה שניות 🤭</p>
    </Alert>
}