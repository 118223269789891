import * as yup from 'yup'

yup.setLocale({
  mixed: {
    required: 'חובה למלא שדה זה',
    notType: 'שדה לא תקין'
  },
  string: {
    email: `כתובת דוא"ל אינה תקינה`
  }
})

const invalidIsraeliID = 'מספר ת.ז לא תקין'

export const validateIsraeliID = id => {
  if (id === undefined) return false

  id = String(id).trim()
  if (id.length > 9 || id.length < 5 || isNaN(id)) return false

  // Pad string with zeros up to 9 digits
  id = id.length < 9 ? ('00000000' + id).slice(-9) : id

  return Array.from(id, Number)
    .reduce((counter, digit, i) => {
      const step = digit * ((i % 2) + 1)
      return counter + (step > 9 ? step - 9 : step)
    }) % 10 === 0
}

yup.addMethod(yup.number, 'israeliId', function () {
  return this.test('israeli_id', invalidIsraeliID, validateIsraeliID)
})
yup.addMethod(yup.string, 'israeliId', function () {
  return this.test('israeli_id', invalidIsraeliID, validateIsraeliID)
})

const invalidPhone = 'מספר טלפון לא תקין'

yup.addMethod(yup.string, 'ILMobile', function () {
  return this.transform(function (value, originalvalue) {
    return value.replaceAll(/-|\(|\)| /g, '')
  }).test('il_mobile', invalidPhone, function (num) {
    if (num === undefined) return false
    return num.substring(0, 2) === '05'
  }).phone('IL', false, invalidPhone)
})

const hebOnlyRegex = /^[\u0590-\u05FF '"-]*$/
const hebOnlyErr = 'נא להשתמש באותיות עבריות בלבד'

function hebrewOnly () {
  return this.matches(hebOnlyRegex, hebOnlyErr)
}

yup.addMethod(yup.string, 'hebrewOnly', hebrewOnly)

export const isRequired = (schema, field) => {
  let fieldsDescriptor = schema.describe().fields
  const fieldParts = field.split('.')
  for (let i = 0; i < fieldParts.length - 1; i++) {
    fieldsDescriptor = fieldsDescriptor[fieldParts[i]].fields
  }
  fieldsDescriptor = fieldsDescriptor[fieldParts[fieldParts.length - 1]]

  return !!fieldsDescriptor.tests.find(t => t.name === 'required')
}

const validateCC = cc => {
  if (cc === undefined) return false

  let sCC = cc.toString().replace(/\D+/g, '')
  switch (sCC.length) {
    case 8:
      sCC = '0' + sCC
    // falls through
    case 9:
      return isracardCheck(sCC)
    case 16:
      if (!(sCC.startsWith('51') || sCC.startsWith('52') || sCC.startsWith('53') || sCC.startsWith('54') ||
        sCC.startsWith('55') || sCC.startsWith('4') || sCC.startsWith('27') || sCC.startsWith('222') ||
        sCC.startsWith('677189'))) {
        return false
      }

      return luhnCheck(sCC)
    default:
      return false
  }
}

const isracardCheck = num => {
  //algorithm explanation: https://web.archive.org/web/20140227235803/http://povolotski.me/2013/09/24/isracard-credit-card-number-validation-2/
  if (typeof num === 'number') num = '' + num
  if (num.length < 8 || num.length > 9) return false
  var sum = 0
  num.split('').forEach(function (val, key) {
    sum += parseInt(val, 10) * (num.length - key)
  })
  return sum % 11 === 0
}
const luhnCheck = value => {
  // Accept only digits, dashes or spaces
  if (/[^0-9-\s]+/.test(value)) return false

  // The Luhn Algorithm. It's so pretty.
  let nCheck = 0, bEven = false
  value = value.replace(/\D/g, '')

  for (let n = value.length - 1; n >= 0; n--) {
    let cDigit = value.charAt(n),
      nDigit = parseInt(cDigit, 10)

    if (bEven && (nDigit *= 2) > 9) nDigit -= 9

    nCheck += nDigit
    bEven = !bEven
  }

  return (nCheck % 10) === 0
}

const invalidCC = 'מספר כרטיס אשראי לא תקין'
yup.addMethod(yup.string, 'CC', function () {
  return this.test('CC', invalidCC, validateCC)
})

const validateExp = exp => {
  if (exp === undefined) return false

  exp = exp.replace(/\D+/g, '')
  if (exp.length < 4) {
    return false
  }
  let m = parseInt(exp.substring(0, 2))
  let y = parseInt(exp.substring(4, 2))

  if (m > 12 || m < 1) {
    return false
  }

  let now = new Date()
  let minYear = now.getFullYear() % 100

  return !((y > minYear + 10) || (y < minYear) || (y === minYear && m <= now.getMonth() + 1))

}
const invalidCCExp = 'תוקף לא תקין'
yup.addMethod(yup.string, 'CCExp', function () {
  return this.test('CCExp', invalidCCExp, validateExp)
})
