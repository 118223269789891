import './Header.scss'
import { BsFillFlagFill } from 'react-icons/bs'
import { MdSecurity } from 'react-icons/md'
import { RiSafeLine } from 'react-icons/ri'
import { useEffect, useState } from 'react'

export default function Header (props) {
  let [title, setTitle] = useState('התפקדות לליכוד')
  let [showHeader, setShowHeader] = useState(true)
  let [customImage, setCustomImage] = useState('')

  useEffect(() => {
    const handler = ({ origin, source, data }) => {
      if (data.type === 'config' && data.payload !== undefined) {
        if (data.payload.showHeader !== undefined) {
          setShowHeader(data.payload.showHeader)
        }
        if (data.payload.title !== undefined) {
          setTitle(data.payload.title)
        }
        if (data.payload.customImage !== undefined) {
          if (data.payload.customImage.toLowerCase().substring(0, 5) === 'https') {
            setCustomImage(data.payload.customImage)
          } else {
            console.log('customImage must use https')
          }
        }
      }
    }

    window.addEventListener('message', handler)

    // clean up
    return () => window.removeEventListener('message', handler)
  }, []) // empty array => run only once

  return <>
    {customImage && <img src={customImage} alt={title}/>}
    {showHeader && <>
      {title && <h1>{title}</h1>}
      <div className={'intro'}>
        <p>שדות חובה מסומנים ב- <strong className={'required'}>*</strong></p>
        <p><MdSecurity/> המידע שתמלאו פה נשמר בצורה מאובטחת ומוצפנת.</p>
        <p><RiSafeLine/> פרטי הטלפון והמייל שלכם לא יעברו לאף אחד, כך שלא תקבלו ספאם!</p>
        <p><BsFillFlagFill/> נא למלא את הטופס בעברית.</p>
      </div>
    </>}
  </>
}
