import {Alert} from "react-bootstrap";

export function SuccessMsg({refID}) {
    return <Alert variant="success">
        <h2>טה דאם! 🎉</h2>
        <p>אנחנו שמחים לבשר לך שהגשת טופס ההתפקדות שלך נקלטה בהצלחה.</p>
        <p><u>שימו לב:</u> קליטתך כחבר ליכוד וחיוב דמי החבר השנתיים יתבצעו לאחר אימות הנתונים ע"י מנהלת הליכוד.
            <br/>
            יש לבדוק בעוד כחודש האם התבצע חיוב בפועל בחברת האשראי על מנת להבטיח את חברותך.</p>
        {refID !== "" && <p>מספר המעקב שלך: <strong>{refID}</strong></p> }
    </Alert>
}