import { Formik } from 'formik'
import { useCallback, useState } from 'react'
import { Button, Form } from 'react-bootstrap'

export default function MultiStepForm (props) {
  const { steps, initialValues } = props

  const isStepEnabled = useCallback((i, values) => {
    if (steps[i].isEnabled === undefined) {
      return true
    }

    return steps[i].isEnabled(values)
  }, [steps])

  const initialStep = () => {
    for (let i = 0; i < steps.length; i++) {
      if (!steps[i].validationSchema.isValidSync(initialValues) && isStepEnabled(i, initialValues)) {
        return i
      }
    }
    return steps.length - 1
  }

  const [step, setStep] = useState(initialStep())

  let locale = {
    next: 'Next',
    prev: 'Previous',
    finish: 'Finish'
  }

  if (props.locale !== undefined) {
    locale = { ...locale, ...props.locale }
  }

  const isLastStep = (values) => {
    let lastStep

    for (lastStep = steps.length - 1; lastStep >= 0; lastStep--) {
      if (isStepEnabled(lastStep, values)) {
        break
      }
    }

    return step === lastStep
  }

  const handleNext = (values) => {
    if (isLastStep(values)) {
      return
    }

    let nextStep
    for (nextStep = step + 1; nextStep < steps.length; nextStep++) {
      if (isStepEnabled(nextStep, values)) {
        break
      }
    }
    setStep(nextStep)
  }

  const handlePrev = (values) => {
    if (step === 0) {
      return
    }
    let prevStep
    for (prevStep = step - 1; prevStep >= 0; prevStep--) {
      if (isStepEnabled(prevStep, values)) {
        break
      }
    }
    setStep(prevStep)
  }
  const prevFunc = ({ values, validateForm, setTouched }) => {
    return () => {
      validateForm().then(_ => setTouched({}))
      handlePrev(values)
    }
  }
  const nextFunc = ({ handleSubmit, validateForm, setTouched, touched }) => {
    return () => {
      validateForm().then(errors => setTouched({ ...touched, ...errors }))
      handleSubmit()
    }
  }

  const NavigationButtons = formikBag => {
    const { isValid, isSubmitting } = formikBag

    const isDisabled = !isValid || isSubmitting

    return <>
      {step > 0 ? <Button onClick={prevFunc(formikBag)} variant="secondary">{locale.prev}</Button> : null}
      {' '}
      <Button onClick={nextFunc(formikBag)} disabled={isDisabled}>
        {isLastStep() ? locale.finish : locale.next}
      </Button>
    </>
  }

  const onSubmit = async (values, formikBag) => {
    const { setSubmitting } = formikBag
    const bag = {
      values: values,
      step: step,
      lastStep: isLastStep(values),
      ...formikBag
    }

    if (!isLastStep()) {
      if (props.onSubmit !== undefined) {
        if (await props.onSubmit(bag)) {
          handleNext(values)
        }
      } else {
        console.log('onSubmit not defined. Going to the next step', values)
        handleNext(values)
      }
      setSubmitting(false)
      return
    }

    if (props.onSubmit !== undefined) {
      props.onSubmit(bag)
    } else {
      console.log('onSubmit not defined. Form completed', values)
    }
    setSubmitting(false)
  }

  const CurrentStep = steps[step]
  const { validationSchema } = CurrentStep

  return <Formik
    initialValues={initialValues}
    validationSchema={validationSchema}
    onSubmit={onSubmit}
    enableReinitialize={true}
  >
    {formikProps => <>
      <Form noValidate onSubmit={onSubmit}>
        <CurrentStep
          {...formikProps}
          {...props}
          next={nextFunc(formikProps)}
          prev={prevFunc(formikProps)}
          NavigationButtons=<NavigationButtons {...formikProps} />
        />
      </Form>
    </>}
  </Formik>
}
