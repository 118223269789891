import { Col, Form, OverlayTrigger, Popover } from 'react-bootstrap'
import { useField } from 'formik'
import { BsInfoCircleFill } from 'react-icons/bs'
import MaskedInput from 'react-maskedinput'
import { isRequired } from './validations'
import DatePicker, { registerLocale } from 'react-datepicker'
import 'react-datepicker/dist/react-datepicker.css'
import he from 'date-fns/locale/he'
import './InputField.scss'
import Select from 'react-select'
import { useRef } from 'react'
import SignaturePad from './SignaturePad'

registerLocale('he', he)

export default function InputField (props) {
  const [field, meta, { setValue }] = useField(props)

  const tooltip = (
    <Popover>
      <Popover.Body>
        {props.children}
      </Popover.Body>
    </Popover>
  )

  if (props.onChange !== undefined) {
    field.onChange = props.onChange
  }

  let ref = useRef()
  field.ref = ref

  let ph = props.placeholder
  switch (props.type) {
    case 'datepicker':
      let d = new Date()
      d.setFullYear(d.getFullYear() - 17)
      field.as = DatePicker
      field.selected = (field.value && new Date(field.value)) || ''
      field.onChange = setValue
      field.dateFormat = 'd/M/yyyy'
      field.popperPlacement = 'bottom-end'
      field.maxDate = d
      field.locale = 'he'
      field.showMonthDropdown = true
      field.showYearDropdown = true
      field.wrapperClassName = !!meta.touched && !!meta.error ? 'is-invalid' : ''
      break
    case 'cc':
      field.type = 'tel'
      field.as = MaskedInput
      field.mask = '1111 1111 1111 1111'
      field.placeholderChar = ' '
      field.style = { direction: 'ltr' }
      field.autoComplete = 'cc-number'
      break
    case 'email':
      field.style = { direction: 'ltr' }
      break
    case 'exp':
      field.type = 'tel'
      field.as = MaskedInput
      field.mask = '11/11'
      field.style = { direction: 'ltr' }
      field.autoComplete = 'cc-exp'
      ph = 'MM/YY'
      break
    case 'phone':
      field.type = 'tel'
      field.as = MaskedInput
      field.mask = '(111) 111-1111'
      field.style = { direction: 'ltr' }
      break
    case 'select':
      const optFromValue = (opts, val) => props.options.find(o => o.value === val)

      field.as = Select
      field.onChange = o => setValue(o.value)
      field.value = optFromValue(props.options, field.value)
      field.options = props.options
      field.bsPrefix = 'form-control-select'
      field.noOptionsMessage = () => 'לא נמצאו אפשרויות מתאימות'
      if (ph === undefined) {
        ph = 'בחר...'
      }
      break
    case 'signature':
      field.as = SignaturePad
      field.onChange = o => setValue(o)
      field.initialValue = field.value
      break
    case 'checkbox':
      field.as = Form.Check
      field.bsPrefix = 'form-control-checkbox'
      field.checked = field.value
      field.value = undefined
    // fall through
    default:
      break
  }
  if (field.name === 'cvc') {
    field.autoComplete = 'cc-csc'
  }
  const controller = <Form.Control
    type={props.type}
    disabled={props.disabled}
    onFocus={props.onFocus}
    {...field}
    placeholder={ph}
    isInvalid={!!meta.touched && !!meta.error}
    isValid={!!meta.touched && !meta.error}
  />

  return <Form.Group as={Col} controlId={field.name}>
    <Form.Label>
      {(props.markRequired || isRequired(props.validationSchema, field.name)) &&
        <strong className={'required'}>* </strong>}
      {props.label}
      {props.children &&
        <OverlayTrigger trigger={['hover', 'focus']} placement="auto" delay={{ show: 250, hide: 400 }}
                        key={field.name} overlay={tooltip}>
          <span> <BsInfoCircleFill/>️</span>
        </OverlayTrigger>}

    </Form.Label>
    {controller}
    <Form.Control.Feedback type="invalid">
      {meta.error}
    </Form.Control.Feedback>
    {props.desc && <Form.Text muted>
      {props.desc}
    </Form.Text>}
  </Form.Group>
}