import { useEffect, useState } from 'react'
import { Alert, Button } from 'react-bootstrap'

export default function CCError ({ tryAgain }) {
  let [timeLeft, setTimeLeft] = useState(25)

  useEffect(() => {
    if (!timeLeft) return

    const intervalId = setInterval(() => {
      setTimeLeft(timeLeft - 1)
    }, 1000)

    // clear interval on re-render to avoid memory leaks
    return () => clearInterval(intervalId)
    // add timeLeft as a dependency to re-rerun the effect
    // when we update it
  }, [timeLeft])

  return <Alert variant="danger">
    <h2>אוי לא 🙀</h2>
    <p>שוקינג. זה אומנם מאוד נדיר, אבל משהו השתבש בשליחת הטופס שלך לליכוד, ככל הנראה תקלת מערכת זמנית.</p>
    <p>בבקשה נסו מאוחר יותר - פשוט רעננו את האתר, אין צורך למלא הכל מההתחלה</p>
    <Button onClick={tryAgain} disabled={timeLeft === 0 ? undefined : true}>
      נסה שוב
      {timeLeft > 0 && ` (${timeLeft})`}
    </Button>
  </Alert>
}