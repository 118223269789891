import { Col, Row } from 'react-bootstrap'
import * as yup from 'yup'
import 'yup-phone'
import '../components/validations'
import InputField from '../components/InputField'
import { useEffect } from 'react'
import { useAnalytics } from 'use-analytics'

const schema = yup.object().shape({
  first_name: yup.string().required().hebrewOnly(),
  last_name: yup.string().required().hebrewOnly(),
  email: yup.string().email().required(),
  phone: yup.string().required().ILMobile(),
  israeli_id: yup.string().required().israeliId(),
  referrer: yup.string().notRequired(),
})
export const PersonalDetails = (props) => {
  let { values: { email } } = props
  const { track, identify } = useAnalytics()

  useEffect(() => {
    track('PersonalDetails')
  }, [track])

  useEffect(() => {
    const delayDebounceFn = setTimeout(() => {
      if (email !== undefined && email !== '') {
        identify('userID', { email: email })
      }
    }, 2000)

    return () => clearTimeout(delayDebounceFn)
  }, [email, identify])

  return <>
    <Row>
      <InputField validationSchema={schema} name="first_name" type="text" placeholder="משה" label="שם פרטי"/>
      <InputField validationSchema={schema} name="last_name" type="text" placeholder="כהן" label="שם משפחה"/>
      <InputField validationSchema={schema} name="israeli_id" type="tel" placeholder="" label="מספר זהות"/>
    </Row>
    <Row>
      <InputField validationSchema={schema} name="email" type="email" placeholder="your@email.com"
                  label="אימייל"/>
      <InputField validationSchema={schema} name="phone" type="phone" placeholder="050-1234-567"
                  label="טלפון נייד">
        <p>אנחנו צריכים את מספר הטלפון שלך כדי ללוות אותך בתהליך, ולדווא שהכל בסדר 🤓</p>
        <p>אל דאגה, לא נעביר את מספר הטלפון שלכם <u>לאף אחד</u> (גם לא למפלגה, כך שלא תקבלו ספאם)</p>
      </InputField>
    </Row>
    <Row>
      <InputField validationSchema={schema} name="referrer" type="text" label="איך הגעת אלינו?">
        מי הפנה אותך אלינו? (למשל: שם של פעיל, קבוצת וואטסאפ/פייסבוק, לינק, תא סטודנטים, וכד')
      </InputField>
    </Row>
    <Row>
      <Col>
        {props.NavigationButtons}
      </Col>
    </Row>
  </>
}

PersonalDetails.label = 'עליך'
PersonalDetails.validationSchema = schema
