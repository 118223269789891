import { Col, Row } from 'react-bootstrap'
import InputField from '../components/InputField'
import { useEffect, useState } from 'react'
import Cards from 'react-credit-cards'
import 'react-credit-cards/lib/styles.scss'
import './PaymentDetails.scss'
import * as yup from 'yup'
import '../components/validations'
import { RiSecurePaymentLine } from 'react-icons/ri'
import { useAnalytics } from 'use-analytics'

const invalidCVC = 'קוד אימות כרטיס לא תקין'
const invalidTerms = 'בכדי להתפקד חובה לאשר את תנאי ההתפקדות וההצהרה'

const schema = yup.object().shape({
  cc: yup.string().required().CC(),
  expiry: yup.string().required().CCExp(),
  cvc: yup.number().when('registration_mode', {
    is: v => v === 'online',
    then: yup.number().required().max(9999, invalidCVC).min(100, invalidCVC),
  }),
  israeli_id: yup.string().required().israeliId(),
  terms: yup.bool().required().oneOf([true], invalidTerms),
  registration_mode: yup.string(),
})

export const PaymentDetails = (props) => {
  const { track } = useAnalytics()
  useEffect(() => {
    track('PaymentDetails')
  }, [track])

  let { cc, expiry, cvc, first_name, last_name, registration_mode, partner } = props.values

  let [focused, setFocused] = useState('')

  const onFocus = e => {
    let name = e.target.name
    if (name === 'cc') name = 'number'
    setFocused(name)
  }

  let [cost, setCost] = useState('64₪ לשנה')

  useEffect(() => {
    if (!partner.enabled) {
      setCost(`64₪ לשנה`)
    } else {
      setCost(`96₪ לשנה לזוג`)
    }
  }, [partner.enabled])

  return <>
    <Row>
      <Col>
        <p><RiSecurePaymentLine/> דמי החבר({cost}) נגבים ישירות על ידי הליכוד. </p>
      </Col>
    </Row>
    <Row>
      <Col>
        <Cards
          cvc={cvc}
          expiry={expiry}
          focused={focused}
          name={`${first_name} ${last_name}`}
          number={cc}
          locale={{ valid: 'תוקף' }}
          acceptedCards={['visa', 'mastercard', 'isracard']}
        />
      </Col>
      <Col>
        <Row>
          <InputField validationSchema={schema} type="cc" name="cc" label="מספר כרטיס אשראי"
                      onFocus={onFocus}/>
        </Row>
        <Row>
          <InputField validationSchema={schema} type="exp" name="expiry" label="תוקף" onFocus={onFocus}/>
          {registration_mode === 'online' && <InputField validationSchema={schema} type="tel" name="cvc" label="קוד אימות כרטיס (CVV)"
                      onFocus={onFocus}>
            מספר בן שלוש ספרות שנועד למטרות אבטחה, ובדרך כלל מופיע בגב כרטיס האשראי או כרטיס החיוב שלך.
            לפעמים הוא נקרא 'קוד אבטחת כרטיס' או 'ערך אימות כרטיס', ומספק הגנה נוספת מפני מעשי הונאה.
          </InputField>}
        </Row>
        <Row>
          <InputField validationSchema={schema} name="israeli_id" type="tel" placeholder=""
                      label="מספר זהות" disabled
                      desc={`מספר הזהות של בעל הכרטיס חייב להיות זהה לפרטי הנרשם (${first_name} ${last_name})`}/>
        </Row>
      </Col>
    </Row>
    <Row>
      <InputField validationSchema={schema} type="checkbox" name="terms"
                  label="אני מאשר את תנאי ההתפקדות לליכוד (העבר עכבר על ה-i לקריאה)">
        אני החתום/ה מטה מבקש/ת להצטרף ולהיות חבר/ה בליכוד, תנועת לאומית ליברלית. אני מצהיר בזה כדלקמן:
        <ul>
          <li>אני מזדהה עם מטרות תנועת הליכוד, כפי שהן מפורטות בסעיף 1 לחוקת הליכוד ועם
            דרכה הרעיונית ובכוונתי להצביע לליכוד בבחירות לכנסת אני מתחייב/ת לא לעשות מעשה שיש בו כדי להוות
            סיוע למפלגה ו/או לתנועה פוליטית שמתחרה עם הליכוד.
          </li>
          <li>איני חבר/ה במפלגה אחרת, ולא הורשעתי בעבירה שיש עימה קלון.</li>
          <li>ידוע לי, כי דמי החבר הינם שנתיים וכי תשלומם יעשה אחת לשנה באמצעות הוראת קבע בהרשאה לחיוב חשבוני
            בבנק או כרטיס אשראי שלי וכי לא תישלח לי הודעה מיוחדת לפני החיוב.
          </li>
          <li>ידוע לי כי דמי החבר השנתיים הינם סך של<b> 64 ש"ח ליחיד וסך של 96 ש"ח לזוג</b> וכן כי שיעור דמי
            החבר השנתיים יקבע מעת לעת, בהתאם להוראות חוקת תנועת הליכוד.
          </li>
          <li>אני מסכים לקבל מהליכוד הודעת דואר אלקטרוני ו/או הודעת מסר קצר. כמו כן אני מרשה לתנועת הליכוד
            להעביר לחברי התנועה את שמי ואת הפרטים שלי לצורך יצירת קשר, לרבות את כתובת הדואר האלקטרוני שלי.
          </li>
        </ul>
      </InputField>
    </Row>
    <Row>
      <Col>
        {props.NavigationButtons}
      </Col>
    </Row>
  </>
}

PaymentDetails.label = 'פרטי תשלום'
PaymentDetails.validationSchema = schema
