import { Button, Col, OverlayTrigger, Popover, Row } from 'react-bootstrap'
import InputField from '../components/InputField'
import * as yup from 'yup'
import '../components/validations'

import cityOptions from '../components/cities.json'
import { useEffect, useState } from 'react'
import { useAnalytics } from 'use-analytics'

const familyStatusOptions = [
  { value: 'single', label: 'רווק/ה' },
  { value: 'married', label: 'נשוי/ה' },
  { value: 'separated', label: 'פרוד/ה' },
  { value: 'divorced', label: 'גרוש/ה' },
  { value: 'widow', label: 'אלמנ/ה' },
]
const genderOptions = [{ value: 'male', label: 'זכר' }, { value: 'female', label: 'נקבה' }]

const schema = yup.object().shape({
  first_name: yup.string().required(),
  last_name: yup.string().required(),
  father_name: yup.string().required().hebrewOnly(),
  birthday: yup.date().required(),
  gender: yup.string().required(),
  family_status: yup.string().required(),
  birth_country: yup.string().notRequired().hebrewOnly(),
  aliya_year: yup.mixed().when('birth_country', {
    is: v => v === undefined || v === '' || v === 'ישראל',
    then: yup.mixed().notRequired(),
    otherwise: yup.number().required(),
  }),
  city: yup.string().required(),
  street: yup.string().required().hebrewOnly(),
  home_num: yup.number().required(),

  partner: yup.object().shape({
    enabled: yup.bool()
  }),

  registration_mode: yup.string(),
  signature: yup.mixed().when('registration_mode', {
    is: v => v === 'offline',
    then: yup.mixed().required(),
  })
})

export const ContactDetails = (props) => {
  const { track } = useAnalytics()
  useEffect(() => {
    track('ContactDetails')
  }, [track])

  let [partnerToggleText, setPartnerToggleText] = useState('')

  let { values: { birth_country, partner, registration_mode }, setFieldValue, next } = props

  useEffect(() => {
    if (!partner.enabled) {
      setPartnerToggleText(`הוספת בן/בת זוג`)
      track('addPartner')
    } else {
      setPartnerToggleText(`הסרת בן/בת זוג`)
      track('removePartner')
    }
  }, [partner.enabled, track])

  const toggleCouple = () => {
    const nv = !partner.enabled
    setFieldValue('partner.enabled', nv)
    if (nv) {
      next()
    }
  }

  const partnerTooltip = (
    <Popover>
      <Popover.Header>כמעט וסיימנו, אבל מה דעתך להוסיף את בן/בת זוגך?</Popover.Header>
      <Popover.Body>
        <p>
          התפקדות זוגית מקנה הנחה של 32 שקלים בדמי החברות השנתיים.
        </p>
      </Popover.Body>
    </Popover>
  )

  return <>
    <Row>
      <InputField validationSchema={schema} disabled name="first_name" type="text" label="שם פרטי"/>
      <InputField validationSchema={schema} disabled name="last_name" type="text" label="שם פרטי"/>
      <InputField validationSchema={schema} name="father_name" type="text" label="שם האב"/>
    </Row>
    <Row>
      <InputField validationSchema={schema} name="birthday" type="datepicker" label="תאריך לידה">
        ההתפקדות מגיל 17 ומעלה
      </InputField>
      <InputField validationSchema={schema} type="select" name="gender" label="מגדר"
                  options={genderOptions}/>
      <InputField validationSchema={schema} type="select" name="family_status" label="מצב משפחתי"
                  options={familyStatusOptions}/>
      <InputField validationSchema={schema} type="text" name="birth_country" label="ארץ לידה"
                  placeholder="ישראל"/>
      {birth_country !== '' && birth_country !== undefined && birth_country !== 'ישראל' &&
        <InputField validationSchema={schema} type="number" name="aliya_year" label="שנת עליה"
                    placeholder="" markRequired/>}
    </Row>
    <Row>
      <InputField validationSchema={schema} type="select" name="city" label="עיר"
                  options={cityOptions}/>
      <InputField validationSchema={schema} type="text" name="street" label="רחוב"/>
      <InputField validationSchema={schema} type="tel" name="home_num" label="מספר בית"/>
    </Row>
    {registration_mode === 'offline' && <Row>
      <InputField validationSchema={schema} type="signature" name="signature" label="חתימה"/>
    </Row>}
    <Row>
      <Col>
        {props.NavigationButtons} {' '}
        <OverlayTrigger trigger={['hover', 'focus']} placement="auto" delay={{ show: 250, hide: 400 }}
                        overlay={partnerTooltip}>
          <Button onClick={toggleCouple}
                  variant={!partner.enabled ? 'outline-success' : 'outline-danger'}>{partnerToggleText}</Button>
        </OverlayTrigger>
      </Col>
    </Row>
  </>
}
ContactDetails.label = 'פרטים אישיים'
ContactDetails.validationSchema = schema
