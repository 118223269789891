import Header from './Header'
import { Alert, Container } from 'react-bootstrap'
import { useNavigate, useParams } from 'react-router-dom'
import { useCallback, useEffect, useState } from 'react'
import * as api from './API'
import { PersonalDetails } from './form/PersonalDetails'
import { ContactDetails } from './form/ContactDetails'
import { PaymentDetails } from './form/PaymentDetails'
import MultiStepForm from './components/MultiStepForm'
import { PartnerDetails } from './form/PartnerDetails'
import CCError from './components/CCError'
import { useAnalytics } from 'use-analytics'
import initVals from './initVals.json'
import { SuccessMsg } from './components/SuccessMsg'
import { ProcessingMsg } from './components/ProcessingMsg'
import { AlreadySignedMsg } from './components/AlreadySignedMsg'

const status_new = 0
const status_already_signed = 1
const status_success = 2
const status_processing = 3
const status_clearing_failed = 4
const status_loading_reg = 5

export default function RegistrationForm (props) {
  const { identify } = useAnalytics()

  let [status, setStatus] = useState(status_new)
  let [refID, setRefID] = useState('')
  let [error, setError] = useState('')
  let { uhash, org: pOrg } = useParams()
  let [org, setOrg] = useState('')
  const navigate = useNavigate()

  if (pOrg !== undefined && pOrg !== org) {
    setOrg(pOrg)
    initVals.org = pOrg
  }

  const tryAgain = () => setStatus(status_new)

  let [initialValues, setInitialValues] = useState(initVals)

  const handleError = useCallback(err => {
    switch (err) {
      case 'already signed':
        setStatus(status_already_signed)
        return
      case 'invalid uhash':
        navigate(`/`)
        setStatus(status_new)
        return
      default:
        console.log('err', err)
        setError(err)
    }
  }, [navigate])

  useEffect(() => {
    if (uhash !== undefined && uhash !== '') {
      setStatus(status_loading_reg)
      api.GetMemberReq(uhash).then(resp => {
        if (resp.data.birthday !== undefined && resp.data.birthday !== null) {
          resp.data.birthday = new Date(resp.data.birthday)
        }
        if (resp.data.partner !== undefined && resp.data.partner.birthday !== undefined && resp.data.partner.birthday !== null) {
          resp.data.partner.birthday = new Date(resp.data.partner.birthday)
        }

        let values = { ...initialValues, ...resp.data }
        if (values.partner.israeli_id !== '') {
          values.partner.enabled = true
        }

        if (values.org === '') {
          values.org = org
        } else {
          setOrg(values.org)
        }

        identify('userID', { email: values.email })
        setInitialValues(values)
        setStatus(status_new)
      }).catch(err => {
        if (err.response === undefined) {
          console.log(err)
          return
        }
        handleError(err.response.data)
      })
    }
  // eslint-disable-next-line
  }, [uhash, identify, org, handleError])

  const handleSubmit = async ({ values, lastStep }) => {
    try {
      setError('')
      let noUHash = false
      if (uhash === undefined || uhash === '') {
        noUHash = true
      }

      let payload = { ...values }
      payload.partner = { ...payload.partner }
      if (payload.cc !== undefined && payload.cc != null) {
        payload.cc = payload.cc.toString().replace(/\D+/g, '').toString()
      }
      if (payload.cvc !== undefined && payload.cvc != null) {
        payload.cvc = payload.cvc.toString()
      }
      if (payload.birthday !== undefined && payload.birthday != null) {
        payload.birthday = `${payload.birthday.getFullYear()}-${String(payload.birthday.getMonth() + 1).padStart(2, '0')}-${String(payload.birthday.getDate()).padStart(2, '0')}`
      }
      if (payload.israeli_id === '') {
        payload.israeli_id = 0
      } else {
        payload.israeli_id = parseInt(payload.israeli_id)
      }
      if (payload.aliya_year === '') {
        payload.aliya_year = 0
      } else {
        payload.aliya_year = parseInt(payload.aliya_year)
      }
      if (payload.home_num === '') {
        payload.home_num = 0
      } else {
        payload.home_num = parseInt(payload.home_num)
      }

      if (!payload.partner.enabled) {
        payload.partner.israeli_id = 0
        payload.partner.birthday = null
      } else {
        if (payload.partner.israeli_id === '') {
          payload.partner.israeli_id = 0
        } else {
          payload.partner.israeli_id = parseInt(payload.partner.israeli_id)
        }
        if (payload.partner.aliya_year === '') {
          payload.partner.aliya_year = 0
        } else {
          payload.partner.aliya_year = parseInt(payload.partner.aliya_year)
        }
        if (payload.partner.home_num === '') {
          payload.partner.home_num = 0
        } else {
          payload.partner.home_num = parseInt(payload.partner.home_num)
        }
        if (payload.partner.birthday !== undefined && payload.partner.birthday != null) {
          payload.partner.birthday = `${payload.partner.birthday.getFullYear()}-${String(payload.partner.birthday.getMonth() + 1).padStart(2, '0')}-${String(payload.partner.birthday.getDate()).padStart(2, '0')}`
        }
      }

      if (lastStep) {
        setStatus(status_processing)
        if (noUHash) {
          let resp = await api.Register(payload)
          uhash = resp.data
        }
        try {
          let resp = await api.SubmitMemberReq(uhash, payload)
          setStatus(status_success)
          setRefID(resp.data)

          return true
        } catch (err) {
          console.log(err)
          setStatus(status_clearing_failed)
          return false
        }
      }

      let resp = await api.Register(payload)
      uhash = resp.data
      navigate(`/${uhash}`)

      return true
    } catch (err) {
      if (err.response !== undefined) {
        handleError(err.response.data)
      }
      console.log('err', err)
      return false
    }
  }

  const locale = {
    next: 'המשך',
    prev: 'לשלב הקודם',
    finish: 'סיום'
  }

  let render = null
  switch (status) {
    case status_already_signed:
      render = <AlreadySignedMsg/>
      break
    case status_processing:
      render = <ProcessingMsg/>
      break
    case status_clearing_failed:
      render = <CCError tryAgain={tryAgain}/>
      break
    case status_success:
      render = <SuccessMsg refID={refID}/>
      break
    case status_loading_reg:
      render = <Alert variant="light">רגע… אנחנו טוענים את הטופס שלך</Alert>
      break
    case status_new:
    default:
      render = <>
        {error !== '' && <Alert variant="danger">
          {error}
        </Alert>}
        <MultiStepForm
          initialValues={initialValues}
          steps={[PersonalDetails, ContactDetails, PartnerDetails, PaymentDetails]}
          locale={locale}
          onSubmit={handleSubmit}
        />
      </>
  }

  return <Container>
    <Header/>
    {render}
  </Container>
}