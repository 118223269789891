import axios from 'axios'

const instance = axios.create({
  baseURL: process.env.REACT_APP_API_URL,
  timeout: 30000,
  headers: { 'X-Client': 'LKD' }
})

export const Register = (payload) => instance.post('/api/member_req/', payload)
export const GetMemberReq = (uhash) => instance.get(`/api/member_req/${uhash}/`)
export const SubmitMemberReq = (uhash, payload) => instance.post(`/api/member_req/${uhash}/`, payload)