import React from 'react'
import { createRoot } from 'react-dom/client'
import { BrowserRouter } from 'react-router-dom'
import reportWebVitals from './reportWebVitals'

import Analytics from 'analytics'
import googleTagManager from '@analytics/google-tag-manager'
import { AnalyticsProvider } from 'use-analytics'
import { originalSourcePlugin } from '@analytics/original-source-plugin'
import App from './App'

const analytics = Analytics({
  app: 'reg.lkd.org.il',
  plugins: [
    googleTagManager({
      containerId: 'GTM-5PVSJFW'
    }),
    originalSourcePlugin(),
  ]
})

const container = document.getElementById('root');
const root = createRoot(container);

root.render(
  <React.StrictMode>
    <AnalyticsProvider instance={analytics}>
      <BrowserRouter>
        <App/>
      </BrowserRouter>
    </AnalyticsProvider>
  </React.StrictMode>
)

// If you want to start measuring performance in your app, pass a function
// to log results (for example: reportWebVitals(console.log))
// or send to an analytics endpoint. Learn more: https://bit.ly/CRA-vitals
reportWebVitals()
